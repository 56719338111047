.recycle-sec-bg {
    background-color: var(--section-bg2);
    padding: 100px 0;
}

.recycle-sec-bg .hero-title {
    font-size: 48px;
    font-weight: 600;
    color: var(--green);
    text-align: center;
}

.sec-recycle h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 20px;
}

.sec-recycle p {
    color: var(--offblack);
}

.sec-recycle h6 {
    color: var(--green)
}

.sec-recycle img {
    border: 2px solid var(--green);
}