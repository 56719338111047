.initiatives-sec-bg .hero-title {
    font-size: 48px;
    font-weight: 600;
    color: var(--green);
    text-align: center;
}

.initiatives-sec-bg {
    background-color: var(--section-bg2);
    padding: 100px 0;
}

.initiatives-sec .hero-title {
    font-size: 48px;
    font-weight: 600;
    color: var(--green);
    text-align: center;
}

.initiatives-sec h3 {
    font-weight: 700;
    color: var(--green);
}

.initiatives-sec p {
    color: var(--offblack);
    margin-bottom: 20px;
}

.initiatives-sec h6 {
    color: var(--green)
}

.initiatives-sec img {
    border: 2px solid var(--green)
}
