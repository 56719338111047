#homecarousel .carousel-caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
    background-color: #000000bd;
    width: 100%;
}