.sec-asset-disposition .hero-title {
    font-size: 48px;
    font-weight: 600;
    color: var(--nav-menu-hover);
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.sec-asset-disposition h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 30px;
    text-align: center;
}

.sec-asset-disposition p {
    color: var(--offblack);
    font-size: 16px;
    margin-bottom: 0px;
}

.sec-cards {
    background-color: var(--section-bg2);
}

.sec-asset-disposition .card {
    transition: all 200ms ease-in-out;
    border-radius: 20px;
    height: 100%;
    padding: 20px;
    background-color:var(--section-bg2);
}

.sec-asset-disposition .card:hover {
    transform: scale(1.01);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}
