.sec-e-parisaraa .hero-title {
    font-size: 64px;
    font-weight: 600;
    margin: 100px 0;
}

.sec-e-parisaraa .num {
    font-size: 36px;
    font-weight: 800;
    margin-right: 20px; /* Adjust the margin-right as per your preference */
    color: #000;
}

.sec-e-parisaraa .para-normal {
    font-size: 16px;
}
