/* Base dropdown menu styles */
.navbar .dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1000; /* Ensure dropdowns appear above other content */
}

.navbar .show > .dropdown-menu {
  display: block;
}

/* Submenu styles */
.navbar .dropdown-submenu {
  position: relative;
}

.navbar .dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
  position: absolute;
  display: none;
}

.navbar .dropdown-submenu.show > .dropdown-menu {
  display: block;
}

/* Additional styling to improve appearance (optional) */
.navbar .dropdown-menu {
  background-color: white; /* Dropdown background color */
  border: 1px solid #ccc;  /* Dropdown border color */
  padding: 0; /* Remove padding for dropdown */
  margin: 0; /* Remove margin for dropdown */
}

.navbar .dropdown-item {
  padding: 10px 20px; /* Padding for dropdown items */
  color: #333; /* Text color for dropdown items */
  text-decoration: none; /* Remove underline from links */
  font-weight: 600;
}

/* 
  #f8f9fa;
  #0056b3
*/
.navbar .dropdown-item:hover,
.navbar .dropdown-item:focus {
  background-color: var(--nav-menu-hover);/* Background color on hover and focus */
  color: var(--white); /* Text color on hover and focus */
}

.navbar .nav-link {
  color: #333; /* Text color for navigation links */
  padding: 10px 20px; /* Padding for navigation links */
  font-weight: 600;
}

.navbar .nav-link:hover,
.navbar .nav-link:focus {
  background-color: #f8f9fa; /* Background color on hover and focus */
  color: var(--nav-menu-hover); /* Text color on hover and focus #333*/
}

.navbar .navbar-brand img {
  height: 30px; /* Height for the brand logo */
}



.dropdown-item.active, .dropdown-item:active {
    color: var(--white);
    background-color: var(--nav-menu-hover);
}