.sec-gallery .heading {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.sec-gallery .col-md-4 {
  margin-bottom: 20px;
}

.sec-gallery .col-md-4 iframe {
  display: block;
  width: 100%;
  height: 250px;
  border: none;
}