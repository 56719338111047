.sec-hindupur .hero-title {
    font-size: 48px;
    font-weight: 600;
    margin-top: 100px;
    margin-bottom: 100px;
}

.sec-hindupur .para-normal {
    font-size: 16px;
    margin-bottom: 40px;
}

.sec-hindupur .cert-text {
    font-size: 14px;
}
