.map-footer {
    filter: grayscale(100%) saturate(0%) contrast(70%) invert(100%);
-webkit-filter: grayscale(100%) saturate(0%) contrast(70%) invert(100%);
-moz-filter: grayscale(100%) saturate(0%) contrast(70%) invert(100%);
border:0;
width: 100%;
height: 250px;
}

.address-sec h3 {
    font-weight: 700;
    margin-bottom: 20px;
}

.address-sec i {
    color: var(--orange);
}

.footer-links a {
    color: #333333;
    transition: all 200ms ease-in-out;
}

.footer-links a:hover {
    color: var(--orange);
    margin-left: 5px;
}

.footer-icons {
    color: var(--nav-menu-hover);
}

.footer-icons:hover {
    color: var(--orange);
}