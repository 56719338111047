.management-sec-bg {
    background-color: var(--section-bg2);
    padding: 100px 0;
}

.about-us .hero-title {
    font-size: 48px;
    font-weight: 600;
    color: var(--green);
    text-align: center;
}

.about-us h5 {
    font-weight: 300;
    color: var(--orange);
}

.about-us h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 20px;
}

.about-us .about-icon {
    height:100px;
    width: 100px;
    border-radius: 50%;
}

.about-us p {
    color: var(--offblack);
    margin-bottom: 20px;
}

.about-us p span {
font-weight: 700;
} 
    
.about-us ul li {
    margin-bottom: 20px;
}

