.waste-handling-sec-bg {
    background-color: var(--section-bg2);
    padding: 100px 0;
}

.waste-handling-sec-bg .hero-title {
    font-size: 48px;
    font-weight: 600;
    color: var(--green);
    text-align: center;
}

.sec-e-waste-handling p {
    color: var(--offblack);
}