.policies-sec-bg {
    padding: 100px 0;
    background-color: var(--section-bg2);
}

.policies-sec .hero-title {
    font-size: 48px;
    font-weight: 600;
    color: var(--green);
    text-align: center;
}

.policies-sec h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 20px;
}

.policies-sec ul li {
    color: var(--offblack);
}

.sec-mission {
    background-color: var(--sections-bg);
}

.sec-mission h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 20px;
}

.sec-mission ul li {
    color: var(--text-color-light-grey);
}

.policies-sec p {
    color: var(--offblack);
}

.parallax-vision {
    background:linear-gradient(0deg,rgba(255, 255, 255, 0.7),rgba(255, 255, 255, 0.5)), url('../../../../public/assets/img/about/policiesAndMission/vision.jpg');
    /* background-image: url('../../../../public/assets/img/about/policiesAndMission/vision.jpg'); */
    height: 300px; 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* .parallax-vision h3 {
    font-weight: 700;
    color: var(--nav-menu);
    margin-bottom: 20px;
} */