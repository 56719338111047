.sec-supply-chain .hero-title {
    font-size: 64px;
    font-weight: 600;
    margin: 100px 0;
}

.sec-supply-chain .heading {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0px;
    margin:50px 0;
}

.sec-supply-chain ul li span {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    font-style: italic;
}

.sec-supply-chain ul li {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
}


.sec-supply-chain .para-normal {
    font-size: 16px;
}