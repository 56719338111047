.sec-papers .heading {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.sec-papers ol li {
    font-size: 24px;
    font-weight: 600;
}

.sec-papers ol li a {
    text-decoration: none;
    font-size: 16px;
    color: #000;
    font-weight:400;
    line-height: 1px;
}
