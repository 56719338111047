.benifits-sec-bg {
    background-color: var(--section-bg2);
    padding: 100px 0;
}

.benifits-sec-bg .hero-title {
    font-size: 48px;
    font-weight: 600;
    color: var(--green);
    text-align: center;
}

.sec-benifits h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 20px;
}

.sec-benifits ul li {
    color: var(--offblack);
}

.sec-benifits p {
    color: var(--offblack);
}
.sec-benifits p span {
    font-weight: 700;
}
