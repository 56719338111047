.media-sec-bg {
    background-color: var(--section-bg2);
    padding: 100px 0;
}

.media-sec-bg .hero-title {
    font-size: 48px;
    font-weight: 600;
    color: var(--green);
    text-align: center;
}

.sec-media h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 20px;
}

.sec-media p {
    margin-bottom: 20px;
    color: var(--offblack);
}

.sec-media .read-more-btn {
    text-decoration: none;
    color: var(--green);
    border: 2px solid var(--green);
    padding: 10px 15px;
    border-radius: 10px;
    text-transform: uppercase;
    transition: all 200ms ease-in-out;
    cursor: pointer;
}

.sec-media .read-more-btn:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: var(--green);
    border: 2px solid var(--green);
    padding: 10px 15px;
    border-radius: 10px;
    text-transform: uppercase;
}

.sec-media ul li {
   color: var(--offblack);
}