.home-services {
    background-color: #f3f3f3;
}

.home-services h5 {
    font-weight: 300;
    color: var(--orange);
    text-align: center;
}

.home-services h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 30px;
    text-align: center;
}

.service-icons {
    font-weight: 300;
    color: var(--orange);
}

.home-services p {
    color: var(--offblack);
    font-size: 16px;
    margin-bottom: 0px;
}

.service-cards {
    transition: all 200ms ease-in-out;
    padding: 30px;
    border-radius: 20px;
}

.service-cards:hover {
    transform: scale(1.01);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.btn-services {
    text-decoration: none;
    color: var(--green);
    border: 2px solid var(--green);
    padding: 10px 15px;
    border-radius: 10px;
    text-transform: uppercase;
    transition: all 200ms ease-in-out;
    cursor: pointer;
}

.btn-services:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: var(--green);
    border: 2px solid var(--green);
    padding: 10px 15px;
    border-radius: 10px;
    text-transform: uppercase;
}