.sec-images-gallery .heading {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.sec-images-gallery .col-md-4 {
    margin-bottom: 20px;
}

.sec-images-gallery .img-container {
    overflow: hidden;
    position: relative;
}

.sec-images-gallery img {
    transition: transform 0.3s ease;
    cursor: pointer;
}

.sec-images-gallery img:hover {
    transform: scale(1.1);
}

.carousel-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.carousel-image {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
    border: 2px solid #fff;
    border-radius: 8px;
}

.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 36px;
    color: #fff;
    cursor: pointer;
    z-index: 1000;
}

.carousel-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
}

.nav-btn {
    font-size: 48px;
    color: #fff;
    cursor: pointer;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
}

.nav-btn:hover {
    background-color: rgba(0, 0, 0, 0.5);
}
