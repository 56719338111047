.sec-solutions-top {
    background-color: var(--sections-bg);
}

.num {
    font-size: 60px;
    letter-spacing: 1px;
    color: var(--nav-menu-hover);
    font-weight: 500;
}
.home-solutions-text-b {
    font-size: 18px;
    color: var(--white);
    font-weight: 500;
    text-decoration: underline;
}

.home-solutions-text-i {
    font-size: 16px;
    color: var(--text-color-light-grey);
    font-weight: 400;
    font-style: italic;
}

.home-solutions-text-n {
    font-size: 16px;
    color: var(--text-color-light-grey);
    font-weight: 400;
}

.home-solutions-heading {
    font-size: 28px;
    font-weight: 700;
    color: var(--offblack);
}

.home-solutions-band {
    background-color: var(--nav-menu-hover);
}

.home-solutions-band p{
    font-size: 24px;
    font-weight: 400;
    color: var(--white);
}

.home-solutions-btn {
    background-color: var(--white);
    color: var(--nav-menu-hover);
    text-decoration: none;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    padding: 10px 20px;
    border-radius: 5px;
}

.sec-solutions .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: var(--nav-menu-hover);
    color: white;
}

.sec-solutions .nav-pills .nav-link {
    color: var(--nav-menu);
}

.home-solutions h5 {
    font-weight: 300;
    color: var(--orange);
    text-align: center;
}

.home-solutions h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 30px;
    text-align: center;
}