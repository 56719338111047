*, body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

:root {
  --green: #029303;
  --orange: #fd6407; 
  --offblack: #333333;
  --sections-bg: #111d19;
  --section-bg2: #f3f3f3;
  --white: #fff;
  --nav-menu-hover:#029303;  /*  #257830;*/
  --text-color-light-grey: #a0a5a3;
  --nav-menu:#182822;

  /* --logo-grad-one: #ff6401;
  --logo-grad-two: #fff;
  --logo-grad-three: rgb(0, 153, 1);
  --logo-grad-threedark: #046A38;


  
  --logo-color-mid: #06038D;
  --logo-color-white: #fff; */
}