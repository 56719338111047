.awards-sec-bg {
    background-color: var(--section-bg2);
    padding: 100px 0;
}

.awards-sec .hero-title {
    font-size: 48px;
    font-weight: 600;
    color: var(--green);
    text-align: center;
}

.awards-sec h3 {
    font-weight: 700;
    color: var(--green);
}

.awards-sec ul li {
    color: var(--offblack);
}

.awards-sec h6 {
    color: var(--green)
}

.awards-sec img {
    border: 2px solid var(--green);
}