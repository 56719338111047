.sec-data-erasure .hero-title {
    font-size: 48px;
    font-weight: 600;
    color: var(--nav-menu-hover);
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.sec-data-erasure h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 30px;
    text-align: center;
}

.sec-data-erasure .btn-data-destruction {
    text-decoration: none;
    color: var(--green);
    border: 2px solid var(--green);
    padding: 10px 15px;
    border-radius: 10px;
    text-transform: uppercase;
    transition: all 200ms ease-in-out;
    cursor: pointer;
}

.sec-data-erasure .btn-data-destruction:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: var(--green);
    border: 2px solid var(--green);
    padding: 10px 15px;
    border-radius: 10px;
    text-transform: uppercase;
}




.sec-data-erasure p {
    color: var(--offblack);
    font-size: 16px;
}

.sec-data-erasure .card {
    transition: all 200ms ease-in-out;
    border-radius: 20px;
    height: 100%;
    padding: 20px;
}

.sec-data-erasure .card:hover {
    transform: scale(1.01);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 
}
