.sec-contact-form .hero-title {
    font-size: 48px;
    font-weight: 600;
    color: var(--nav-menu-hover);
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.sec-contact-form h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 30px;
    text-align: center;
}