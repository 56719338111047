.sec-data-center .hero-title {
    font-size: 64px;
    font-weight: 600;
}
.sec-data-center {
    padding: 100px 0;
    background-color: var(--section-bg2);

}
.sec-data-center .heading {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0px;
}

.sec-data-center ul li {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
}

.sec-data-center .para-normal {
    font-size: 16px;
}

.sec-data-center img {
    width: 100%;
    max-width:600px;
    height:auto;
    margin: 10px 0;
    /* margin-bottom: 20px; */
}