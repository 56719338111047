.home-about h5 {
    font-weight: 300;
    color: var(--orange);
}

.home-about h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 20px;
}

.home-about p {
    color: var(--offblack);
}

.btn-cont {
    background-color: var(--orange);
    border-radius: 10px;
    padding: 5px 10px;
    border: 2px solid var(--orange);
    color: white;
    transition: all 200ms ease-in-out;
    text-decoration: none;
    cursor: pointer;
}

.btn-cont:hover {
    background-color: white;
    border-radius: 10px;
    padding: 5px 10px;
    border: 2px solid var(--orange);
    color: var(--orange);
}

.btn-know-more {
    background-color: white;
    border-radius: 10px;
    padding: 5px 10px;
    border: 2px solid var(--green);
    color: var(--green);
    transition: all 200ms ease-in-out;
    text-decoration: none;
    cursor: pointer;
}

.btn-know-more:hover {
    background-color: var(--green);
    border-radius: 10px;
    padding: 5px 10px;
    border: 2px solid var(--green);
    color: white;
}