.sec-branch-location {
    margin: 100px 0;
}

.sec-branch-location .hero-title {
    font-size: 48px;
    font-weight: 600;
    color: var(--nav-menu-hover);
    text-align: center;
}

.sec-branch-contact h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 30px;
    text-align: center;
}

.sec-branch-contact {
    background-color:var(--section-bg2);
}
.sec-branch-contact .card {
    transition: all 200ms ease-in-out;
    border-radius: 20px;
    height: 100%;
    padding: 20px;
    background-color:var(--section-bg2);
}

.sec-branch-contact .card:hover {
    transform: scale(1.01);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.sec-branch-contact p {
    color: var(--offblack);
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 500;
}

.sec-branch-contact p span {
   font-weight: bold;
}

/* .sec-three p {
    color: var(--offblack);
    font-size: 16px;
    margin-bottom: 0px;
}

.sec-three .card {
    transition: all 200ms ease-in-out;
    border-radius: 20px;
    height: 100%;
    padding: 20px;
}

.sec-three .card:hover {
    transform: scale(1.01);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 
}

.sec-three h3 {
    font-weight: 700;
    color: var(--green);
    margin-bottom: 30px;
    text-align: center;
} */

